<template>
  <div class="container">
    <!-- <div class="header">
        <a href="javascript:;" class="back" @click="back">返回</a>
        <h1>景区客流</h1>
    </div> -->
    <div class="top">
      <div class="time">更新时间：{{time}}</div>
      <img src="../../hotel/img/icon-waring.png" alt="提示" />以下数据仅供参考
    </div>
    <div class="top3">
      <div class="title"><span>实时客流TOP3</span></div>
      <div class="content">
        <div class="sort"><label class="orange"><i></i>当前客流量</label><label class="white"><i></i>最大承载量</label></div>
        <div class="items">
          <div class="item" v-for="(item, index) in rank" :key="index">
            <div class="itemname">{{item.name}}</div>
            <div class="iteminfo">
              <div class="progress" :class="proportion(item.inNum,item.maxNum) >= 50 ? (proportion(item.inNum,item.maxNum) >= 80 ? ' waring' : ' crowding') : ''"><div :style="{width: proportion(item.inNum,item.maxNum) + '%'}"></div></div>
              <li><span>{{item.inNum}}人</span> / {{item.maxNum}}人</li>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blockname">景区舒适度</div>
    <div class="jq">
      <div class="loading" v-show="loading"><img src="../../hotel/img/loading.png" alt="正在加载" />正在加载</div>
      <div class="box" @click="toDetail(item)" :class="proportion(item.inNum,item.maxNum) >= 50 ? (proportion(item.inNum,item.maxNum) >= 80 ? ' waring' : ' crowding') : ''" :style="{background: 'url('+ item.ima +') no-repeat center center/cover'}" v-for="(item, index) in states" :key="index">
        <div class="cover"></div>
        <div class="info">
          <span class="level">{{item.level}}</span>
          <div class="title">{{item.name}}</div>
          <div class="left">
            <div class="showdata" v-if="alive"></div>
            <div class="person"><span>实时客流 {{item.inNum}} 人</span></div>
            <div class="person">最大承载 {{item.maxNum}} 人</div>
          </div>
          <div class="right">
            <div class="weather">
              <h1><img src="../../hotel/img/icon-weather.png" alt="天气" />天气</h1>
              <div class="list">
                <li v-for="(weather, index) in item.weather" :key="index">{{weather.name}}<span>{{weather.minTmp}}°C - {{weather.maxTmp}}°C</span>{{weather.condTex}}</li>
              </div>
            </div>
            <div class="parking">
              <h1><img src="../../hotel/img/icon-parking.png" alt="车位" />停车场车位余量</h1>
              <div class="leftparking" v-if="item.allParkig">剩余<em>{{item.remainParking}}</em></div>
              <div class="leftparking" v-else>暂未接入数据</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="box" style="background: url(images/bg-lsdf.png) no-repeat center center/cover">
          <span class="level">5A</span>
          <div class="title">乐山大佛风景区</div>
          <div class="left">
              <div class="showdata" id="cadre2"></div>
              <div class="person"><span>10888</span></div>
              <div class="person">18000</div>
          </div>
          <div class="right">
              <div class="weather">
                  <h1><img src="../../hotel/img/icon-weather.png" alt="天气" />天气</h1>
                  <div class="list">
                      <li>山顶(金顶)<span>20°C - 27°C</span>阴</li>
                  </div>
              </div>
              <div class="parking">
                  <h1><img src="../../hotel/img/icon-parking.png" alt="车位" />停车场车位余量</h1>
                  <div class="leftparking">剩余<em>67</em></div>
              </div>
          </div>
      </div>
      <div class="box" style="background: url(images/bg-dffd.png) no-repeat center center/cover">
          <span class="level">5A</span>
          <div class="title">东方佛都风景区</div>
          <div class="left">
              <div class="showdata" id="cadre3"></div>
              <div class="person"><span>2888</span></div>
              <div class="person">8000</div>
          </div>
          <div class="right">
              <div class="weather">
                  <h1><img src="../../hotel/img/icon-weather.png" alt="天气" />天气</h1>
                  <div class="list">
                      <li>山顶(金顶)<span>19°C - 25°C</span>阴</li>
                  </div>
              </div>
              <div class="parking">
                  <h1><img src="../../hotel/img/icon-parking.png" alt="车位" />停车场车位余量</h1>
                  <div class="leftparking">剩余<em>67</em></div>
              </div>
          </div>
      </div> -->
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import * as echarts from 'echarts';
  export default {
    name: "index",
    data() {
      return {
        loading: true,
        alive:true,
        time: '',
        rank: [], // top3
        states: [] // 景区列表
      }
    },
    created() {
      this.getData()
    },
    beforeUnmount(){
      let roseCharts = document.getElementsByClassName('showdata');
      for(let i = 0;i < roseCharts.length;i++){
        echarts.dispose(roseCharts[i]);
      }
      // console.log(echarts.getInstanceByDom(roseCharts[0]))
    },
    methods: {
      // 获取时间
      getTime() {
        let myDate = new Date(),
          year = myDate.getFullYear(),
          month = myDate.getMonth() + 1,
          day = myDate.getDate(),
          hour = myDate.getHours(),
          minutes = myDate.getMinutes()
        if(month < 10){
          month = '0' + month
        }
        if(day < 10){
          day = '0' + day
        }
        if(hour < 10){
          hour = '0' + hour
        }
        if(minutes < 10){
          minutes = '0' + minutes
        }
        this.time = year + '-' + month + '-' + day + '  ' + hour + ':' + minutes
      },
      // 获取数据
      getData() {
        let _t = this;
        axios.get('https://www.zyou.cloud/bigdataapi/bigdata/api/zwyCont/scenicState.json').then(response => {
          _t.getTime()
          _t.loading = false
          let rank = []
          _t.alive = false;
          for(let i = 0; i < 3; i++){
            rank.push(response.data.results.rank[i])
          }
          _t.rank = rank
          _t.states = response.data.results.states
          _t.$nextTick(() => {
            _t.alive = true;
            setTimeout(() => {
              _t.drawRose()
            }, 100)
          });
        }, response => {
          console.log("error")
        })
      },
      // 查看景区详情
      toDetail(v) {
        // 根据token判断是否跳转登录
        if(this.$global.token){
          this.$router.push('/'+v.interlinkage)
          /*this.$router.push({
              path: v.interlinkage,
              query:{
                  back: 'self'
              }
          })*/
        }else{
          this.isShowLoginPop = true
        }
      },
      // 渲染echarts
      drawRose() {
        let roseCharts = document.getElementsByClassName('showdata')
        for(let i = 0; i < roseCharts.length; i++){
          let cadre = echarts.init(roseCharts[i])
          let nowPerson = ((parseInt(this.states[i].inNum) / parseInt(this.states[i].maxNum)) * 100).toFixed(2) // 百分比
          let comfort = '舒适' // 当前景区状态
          if(nowPerson > 80){
            comfort = '爆满'
          }else if(nowPerson > 50){
            comfort = '拥挤'
          }
          cadre.clear();
          cadre.setOption({
            title: {
              text: comfort,
              textStyle: {
                color: 'rgba(255, 255, 255, 1)',
                fontSize: 14,
                fontWeight: '400',
              },
              subtext: nowPerson + '%',
              subtextStyle: {
                color: 'rgba(255, 255, 255, 1)',
                fontSize: 12,
                fontWeight: '100',
              },
              left: 'center',
              top: '35%'
            },
            angleAxis: {
              max: 100,
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                show: false
              },
              splitLine: {
                show: false
              },
              clockwise: true, // 逆时针
            },
            radiusAxis: {
              type: 'category',
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                show: false
              },
              splitLine: {
                show: false
              }
            },
            polar: {
              center: ['50%', '50%'],
              radius: '100%, 0'
            },
            series: [{
              type: 'bar',
              data: [{
                name: '景区客流量',
                value: nowPerson,
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: '#98dc4f'
                    }, {
                      offset: 1,
                      color: '#12c68d'
                    }])
                  }
                }
              }],
              coordinateSystem: 'polar',
              roundCap: true,
              barWidth: 10,
              barGap: '-100%',
              z: 1
            }, {
              type: 'bar',
              data: [{
                value: 100,
                itemStyle: {
                  color: 'rgba(255, 255, 255, 0.2)',
                  shadowColor: 'rgba(0, 0, 0, 0.2)',
                  shadowBlur: 5,
                  shadowOffsetY: 2
                }
              }],
              coordinateSystem: 'polar',
              roundCap: true,
              barWidth: 10,
              barGap: '-100%'
            }]
          },true)
          // 爆满时的 饼图颜色
          if(nowPerson > 80){
            cadre.setOption({
              series: [{
                type: 'bar',
                data: [{
                  name: '景区客流量',
                  value: nowPerson,
                  itemStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#f67f01'
                      }, {
                        offset: 1,
                        color: '#df2323'
                      }])
                    }
                  }
                }],
                coordinateSystem: 'polar',
                roundCap: true,
                barWidth: 10,
                barGap: '-100%',
                z: 1,
              }, {
                type: 'bar',
                data: [{
                  value: 100,
                  itemStyle: {
                    color: 'rgba(255, 255, 255, 0.2)',
                    shadowColor: 'rgba(0, 0, 0, 0.2)',
                    shadowBlur: 5,
                    shadowOffsetY: 2
                  }
                }],
                coordinateSystem: 'polar',
                roundCap: true,
                barWidth: 10,
                barGap: '-100%'
              }]
            },true)
          }else if(nowPerson > 50){ // 拥挤时的 饼图颜色
            cadre.setOption({
              series: [{
                type: 'bar',
                data: [{
                  name: '景区客流量',
                  value: nowPerson,
                  itemStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#df5c23'
                      }, {
                        offset: 1,
                        color: '#f5b10e'
                      }])
                    }
                  }
                }],
                coordinateSystem: 'polar',
                roundCap: true,
                barWidth: 10,
                barGap: '-100%',
                z: 1,
              }, {
                type: 'bar',
                data: [{
                  value: 100,
                  itemStyle: {
                    color: 'rgba(255, 255, 255, 0.2)',
                    shadowColor: 'rgba(0, 0, 0, 0.2)',
                    shadowBlur: 5,
                    shadowOffsetY: 2
                  }
                }],
                coordinateSystem: 'polar',
                roundCap: true,
                barWidth: 10,
                barGap: '-100%'
              }]
            },true)
          }
        }
      },
      // 计算比例
      proportion(val,max){
        return ((val / max) * 100).toFixed(2)
      },
      // 后退
      back() {
        this.$router.go(-1)
      }
    }
  }
</script>
<style scoped>
  .container{
    /* padding-top: 50px; */
    font-size: 0;
    color: #fff;
    text-align: center;
  }
  .container em, .container li, .container h1{
    font-style: normal;
  }
  .container li{
    list-style-type: none;
  }
  .header{
    height: 50px;
    position: fixed;
    top: 0;
    width: 100%;
    text-align: center;
    background: #fff;
    z-index: 10;
    box-shadow: 0 0 5px #ccc;
  }
  .header a{
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background: url(../../hotel/img/black_back.png) no-repeat center center/auto 18px;
    font-size: 0;
  }
  .header h1{
    color: #333;
    font-size: 16px;
    line-height: 50px;
  }
  .top{
    background: #ffd8c2;
    color: #fb8104;
    text-align: left;
    padding: 0 15px;
    font-size: 13px;
    line-height: 40px;
    white-space: nowrap;
  }
  .top .time{
    float: right;
  }
  .top img{
    height: 20px;
    vertical-align: middle;
    margin: -3px 8px 0 0;
  }
  .top3{
    margin: 15px;
    background: #25383c;
    border-radius: 5px;
  }
  .top3 .title{
    color: #30df94;
    background: #3f6164;
    line-height: 40px;
    font-size: 16px;
    text-align: left;
    border-radius: 5px 5px 0 0;
    box-sizing: border-box;
    padding: 0 10px;
  }
  .top3 .title span{
    font-weight: bold;
    background: linear-gradient(to right, #98dc4f, #1ddfa1);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
  .top3 .content{
    padding: 0 10px 10px 10px;
  }
  .top3 .content .sort{
    text-align: right;
    padding-top: 5px;
  }
  .top3 .content .sort label{
    font-size: 12px;
    line-height: 20px;
    margin-left: 20px;
  }
  .top3 .content .sort label i{
    width: 12px;
    height: 12px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 2px;
    margin-top: -3px;
    margin-right: 5px;
  }
  .top3 .content .sort .orange{
    color: #f67f01;
  }
  .top3 .content .sort .orange i{
    background: #f67f01;
  }
  .top3 .content .sort .white{
    color: #fff;
  }
  .top3 .content .sort .white i{
    background: #fff;
  }
  .top3 .content .item{
    text-align: left;
    margin-top: 15px;
  }
  .top3 .content .itemname{
    width: 90px;
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
    padding-top: 5px;
  }
  .top3 .content .iteminfo{
    width: calc(100% - 90px);
    display: inline-block;
    vertical-align: top;
  }
  .top3 .content .iteminfo li{
    font-size: 14px;
    line-height: 30px;
  }
  .top3 .content .iteminfo li span{
    color: #f67f01;
  }
  .top3 .content .iteminfo .progress{
    border-radius: 0 10px 10px 0;
    height: 20px;
    background: #3f6164;
    margin-top: 5px;
  }
  .top3 .content .iteminfo .progress div{
    height: 20px;
    display: inline-block;
    border-radius: 0 10px 10px 0;
    background: linear-gradient(to right, #12c68d , #98dc4f);
  }
  .top3 .content .iteminfo .crowding div{
    background: linear-gradient(to right, #f5ed0e , #f67f01);
  }
  .top3 .content .iteminfo .waring div{
    background: linear-gradient(to right, #f67f01 , #df2323);
  }
  .blockname{
    font-size: 16px;
    line-height: 16px;
    text-align: left;
    color: #333;
    padding: 5px 15px 0 15px;
  }
  .jq{
    padding: 0 15px 15px 15px;
  }
  .jq .cover{
    background: rgba(0, 0, 0, .65);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 5px;
  }
  .jq .info{
    position: relative;
  }
  .jq .box{
    position: relative;
    border-radius: 5px;
    margin-top: 15px;
    padding-bottom: 15px;
    overflow: hidden;
  }
  .jq .box .level{
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 5px 0 5px 0;
    background: #1ddfa1;
    font-size: 14px;
    line-height: 20px;
    padding: 0 10px;
  }
  .jq .crowding .level{
    background: #ec9113;
  }
  .jq .waring .level{
    background: #df5c23;
  }
  .jq .box .title{
    font-size: 16px;
    line-height: 40px;
  }
  .jq .box .left{
    width: 45%;
    display: inline-block;
    vertical-align: top;
  }
  .jq .box .left .showdata{
    height: 120px;
  }
  .jq .box .left .person{
    font-size: 14px;
    line-height: 20px;
  }
  .jq .box .left .person span{
    background: linear-gradient(to right, #98dc4f, #1ddfa1);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
  .jq .crowding .left .person span{
    background: linear-gradient(to right, #f5ed0e, #df5c23);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
  .jq .waring .left .person span{
    background: linear-gradient(to right, #f67f01 , #df2323);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
  .jq .box .right{
    width: 55%;
    display: inline-block;
    vertical-align: top;
    text-align: left;
  }
  .jq .box .right img{
    width: 20px;
    vertical-align: middle;
    margin: -2px 5px 0 0;
  }
  .jq .box .right h1{
    font-size: 14px;
    line-height: 20px;
  }
  .jq .box .right .weather{
    padding-top: 10px;
  }
  .jq .box .right .list{
    height: 50px;
    padding: 5px 0;
    overflow: hidden;
  }
  .jq .box .right .list li{
    font-size: 12px;
    line-height: 25px;
    padding-left: 26px;
    white-space: nowrap;
  }
  .jq .box .right .list li span{
    margin: 0 10px;
  }
  .jq .box .right .leftparking{
    background: rgba(255, 255, 255, .5);
    border-radius: 10px 0 10px 0;
    line-height: 40px;
    font-size: 16px;
    margin-right: 15px;
    box-sizing: border-box;
    padding-left: 26px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 10px;
    height: 40px;
  }
  .jq .box .right .leftparking em{
    margin-left: 10px;
  }
  .jq .loading{
    font-size: .4rem;
    padding: .2rem;
    color: #ccc;
  }
  .jq .loading img{
    height: .4rem;
    vertical-align: middle;
    margin: -.1rem .1rem 0 0;
    animation: loading 0.5s linear infinite;
  }
  /*子菜单显示动画*/
  @keyframes loading {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(180deg);
    }
  }
</style>
